// Helpers for rendering site templates related to time and date. They require a very heavy moment.js library
// and should only be inlcuded by templates, that need it.

const Handlebars = require('handlebars');
const moment = require('moment');
require('moment/locale/ja');

module.exports = () => {
  Handlebars.registerHelper('ifHomepageOrDetail', function (options) {
    const site = options && options.data && options.data.root ? options.data.root.site : null;

    if (site && (site.activePage === 'index' || site.activePage === 'detail')) {
      return options.fn(this);
    }
    return options.inverse(this);
  });

  Handlebars.registerHelper('durationFormat', function (millis = 0) {
    return moment.duration(millis, 'ms').toISOString();
  });

  Handlebars.registerHelper('millisToDate', function (millis, options) {

    const date = new Date(parseInt(millis));
    const format = options.hash['format'];
    if (format === 'ISO') {
      //Short circuit the creation of a moment object since it's not needed
      //(and might cause some issues with some templates)
      return date.toISOString();
    }
    let momentDate = moment(date);
    let dateFormat;
    if (format) {
      dateFormat = format;
    } else {
      //default to this format if none is set
      dateFormat = 'Month Day, Year';
    }

    if (options.data.root.locale && options.data.root.locale.momentLng) {
      momentDate.locale(options.data.root.locale.momentLng); //set the moment library to use the site language
    } else {
      momentDate.locale('en'); //default to english if no language is set
    }

    // allow for (optional) conversion to match server stored UTC time
    if (options.hash['utc']) {
      momentDate = momentDate.utc();
    }

    switch (dateFormat) {
      case 'Month Day, Year':
        return momentDate.format('MMMM Do, YYYY');
      case 'MM/DD/YY':
        return momentDate.format('L');
      default:
        // previous cases just for legacy purposes
        // the format permaeter will now just accept moment format strings
        return momentDate.format(dateFormat);
    }
  });

  Handlebars.registerHelper('km_from_now', function (video) {
    moment.locale('en');
    return moment(new Date(video.publishedDate), 'YYYYMMDD').fromNow();
  });

};

