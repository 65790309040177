module.exports = {
  sites: {
    port: 5001,
  },
  liveStatus: {
    endpointBaseUrl: 'http://localhost.brightcove.com:4000/',
  },
  edgeApi: {
    endpointBaseUrl: 'https://edge.api.brightcove.com/playback/v1/accounts/',
  },
};
