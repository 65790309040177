
const { getSortMethod } = require('./sorting');
/**
 * Return player template url for a video
 * @param video
 */
function getPlayerTemplateUrl () {
  return window.bcGallery.getTemplatePath('/components/perform_player.hbs');
}

function parseUrlParams () {
  const search = global.location.search.substr(1);
  const hash = {};
  if (!search) {
    return hash;
  }
  const tuples = search.split('&');
  for (let i = 0, j = tuples.length; i < j; i += 1) {
    const pair = tuples[i].split('=');
    hash[pair[0]] = pair[1];
  }
  return hash;
}

function getImageTranscoder () {
  return global.BCLS.imageTranscoder;
}

// Ajaxy themes with custom URL structures must override this method.
// Each player invokes BCLS.utils.nextVideo() when finished.
function nextVideo (useLocalStorageForSort) {
  if (global.BCLS.overrideAutoplay) {
    if (global.BCLS.performOverrideAutoplay) {
      global.BCLS.performOverrideAutoplay();
    }
    return;
  }
  if (global.site && global.site.isEdit || global.bcGallery.mute || global.BCLS.forceDisableAutoplay) {
    return;
  }

  const buildQueryString = function (startingSegments) {
    let qsTokens = [];

    let startsWithPage = false;
    if (startingSegments && startingSegments.length > 0) {
      qsTokens = qsTokens.concat(startingSegments);

      for (let i = 0; i < startingSegments.length; i++) {
        if (startingSegments[i].indexOf('page') >= 0) {
          startsWithPage = true;
        }
      }
    }

    const parsedQuery = parseUrlParams();
    const page = (parsedQuery && parsedQuery.page) ? parsedQuery.page : undefined;
    if (page && !startsWithPage) {
      qsTokens.push('page=' + page);
    }

    const sortMethod = getSortMethod(useLocalStorageForSort);
    if (sortMethod) {
      qsTokens.push('sort_by=' + sortMethod);
    }

    if (global.bcGallery.mute) {
      qsTokens.push('mute=true');
    }

    const searchQuery = parsedQuery && parsedQuery.q ? parsedQuery.q : undefined;
    if (searchQuery) {
      qsTokens.push('q=' + searchQuery);
    }

    if (qsTokens.length > 0) {
      return '?' + qsTokens.join('&');
    }

    return '';
  };

  const videoId = global.currentVideo.id;
  const category = (global.category && typeof global.category.slug !== 'undefined') ? global.category.slug : undefined;
  if (!category || !videoId) {
    return;
  }

  const url = global.baseUrl + '/api/videos/' + category + '/video/' + videoId + '/next' + buildQueryString();
  global.bcGallery.$.ajax(url, {
    dataType: 'json',
    success: function (data) {
      if (data.result) {
        const videoId = data.result.id;
        const videoSlug = data.result.slug;

        const startingSegments = [];
        const browser = global && global.videojs && global.videojs.browser;
        if (browser && !(browser.IS_IOS || browser.IS_ANDROID)) {
          startingSegments.push('autoStart=true');
        }
        if (data.result.page) {
          startingSegments.push('page=' + data.result.page);
        }

        let location = `${global.baseUrl}/detail/videos/${category}/video/${videoId}`;
        if (!global.site || !global.site.seo || !global.site.seo.disableVideoTitleInUrl) {
          location += `/${videoSlug}`;
        }
        location += buildQueryString(startingSegments);
        global.location = location;
      }
    },
    complete: function (xhr) {
      if (xhr.status === 401) {
        window.location.replace(global.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
      }
    },
  });
}

// a URL being set in a template in preview mode needs to preserve the
// device parameter (esp. important for mobile CTAs in preview)
// the previewView.js is responsible for adding 'device-type' to the
// body tag when viewing in preview mode
function urlWithDevice (url) {
  const device = global.bcGallery.$('body').data('device-type');
  if (!device || device.length === 0) {
    return url;
  } else if (url.indexOf('device=') >= 0) {
    return url;
  }

  let deviceUrl = url;
  if (url.indexOf('?') >= 0) {
    deviceUrl = deviceUrl + '&device=' + encodeURIComponent(device);
  } else {
    deviceUrl = deviceUrl + '?device=' + encodeURIComponent(device);
  }
  return deviceUrl;
}

function getVideoDescription (video) {
  const site = global.site;
  let description;
  if (site && site.useLongDescription && video.longDescription) {
    description = video.longDescription;
  } else {
    description = video.shortDescription;
  }
  return description || '';
}

module.exports = {
  getPlayerTemplateUrl,
  parseUrlParams,
  getImageTranscoder,
  nextVideo,
  urlWithDevice,
  getVideoDescription,
};
