// Variable to be exposed to the client in the minified js.
const env = process.env.NODE_ENV || 'development';
const path = require('path');
require('dotenv').config({
  path: path.resolve(__dirname, '..', '.environments', `${env}.env`),
});

const extend = require('node.extend');
let config = {};
try {
  config = require('./environments/' + env);
} catch (e) {
  throw new Error('invalid Client environment specified:', env);
}

module.exports = extend(true, config, {
  env: env,
});
