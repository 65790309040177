module.exports = {
  sites: {
    port: 80,
    hostname: 'bcvp0rtal.com',
  },
  liveStatus: {
    endpointBaseUrl: 'https://portals.assets.brightcove.com/live-status',
  },
  edgeApi: {
    endpointBaseUrl: 'https://edge.api.brightcove.com/playback/v1/accounts/',
  },
};
