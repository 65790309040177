class VideoDownloads {
  constructor (options) {
    this._options = options || {};
  }

  createDownloadSource (options, videoId) {
    const url = options.downloadProxyUrl + `/api/gallery/account/${options.accountId}/site/${options.siteSlug}/video/${videoId}/download`;
    return { default: url };
  }

  getSources (videoId, callback) {
    const options = this._options;
    const self = this;
    const downloadOptions = options.downloadOptions || {};
    const enableCriteria = downloadOptions.enableCriteria;
    const customFieldName = downloadOptions.customField;
    const customFieldValue = downloadOptions.customFieldValue;

    if (['all', 'custom'].indexOf(enableCriteria) === -1) {
      return callback({});
    }

    if (enableCriteria === 'custom' && (!customFieldName || !customFieldValue)) {
      return callback({});
    }
    const edgeApi = window.bcGallery && window.bcGallery.filteredAssemblerData
      && window.bcGallery.filteredAssemblerData.edgeApi;

    const url = edgeApi + options.accountId + '/videos/' + videoId;
    global.bcGallery.$.ajax({
      url: url,
      dataType: 'json',
      jsonp: false,
      headers: {
        accept: 'application/json;pk=' + options.policyKey,
      },
    })
      .done(function (video) {
        const customFields = video.custom_fields || {};
        const hasDownloadableRendition = video.sources.length && video.sources.find(video => video.avg_bitrate);
        if (hasDownloadableRendition && (enableCriteria === 'all' || customFields[customFieldName] === customFieldValue)) {
          return callback(self.createDownloadSource(options, videoId));
        }
        return callback({});
      })
      .fail(function () {
        callback({});
      });
  }

}

module.exports = {
  VideoDownloads,
};
