module.exports.SOURCES = {
  BRIGHTCOVE: 'brightcove',
  YOUTUBE: 'youtube',
};

// In seconds
module.exports.TIME = {
  MINUTE: 60,
  HOUR: 60 * 60,
  DAY: 24 * 60 * 60,
  WEEK: 7 * 24 * 60 * 60,
  MONTH: 30 * 24 * 60 * 60,
  YEAR: 365.25 * 24 * 60 * 60,
};

module.exports.SHARE_TOKENS = {
  VIDEO_URL: '[[VIDEO_URL]]',
  VIDEO_TITLE: '[[VIDEO_TITLE]]',
  VIDEO_SHORT_DESC: '[[VIDEO_SHORT_DESC]]',
  VIDEO_RELATED_LINK_URL: '[[VIDEO_RELATED_LINK_URL]]',
  PAGE_URL: '[[PAGE_URL]]',
  EVENT_TITLE: '[[EVENT_TITLE]]',
  EVENT_DESC: '[[EVENT_DESC]]',
};

module.exports.SHARE_CONFIG = {
  TWITTER_SHORT_URL_CHAR_COUNT: 23,
};

module.exports.KM_CONSTANTS = {
  KM_USER_ROLES: {
    DIRECT: 'Direct',
    DEALER: 'Dealer',
    SERVICE: 'Service',
  },
  KM_CUSTOM_FIELDS: {
    DIRECT: 'direct',
    DEALER: 'dealer',
    SERVICE: 'service',
  },
};

module.exports.SVG_PATHS = {
  'chevron-down': 'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z',
  'chevron-up': 'M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z',
  'chevron-right': 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z',
  'close': 'M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z',

};
