const _ = require('underscore');
const $ = require('jquery');
global.jQuery = $;
require('chosen-js');
const storageKey = 'sortMethod';
let sortWrapperVisible = false;
let $sortVideos;

// ---------------------------------------- private
// redirects the page to include the sort_by param (or replaces if exists)
function sortPage (sortMethod) {
  const params = getQueryParams();
  params.sort_by = sortMethod;

  const queryParams = _.reduce(_.pairs(params), function (total, param) {
    if (!_.isEmpty(param)) {
      total.push(param.join('='));
    }
    return total;
  }, []);

  const location = window.location;
  const redirectUrl = location.origin + location.pathname + '?' + queryParams.join('&');
  window.location.href = redirectUrl;
}

function getQueryParams () {
  const currentParams = _.compact(window.location.search.substr(1).split('&'));

  const params = _.reduce(currentParams, function (total, param) {
    const pair = param.split('=');
    const key = pair[0];
    const value = pair[1];

    total[key] = value;
    return total;
  }, {});

  return params;
}

// if there's a query param we can grab, that takes precedence (static sites)
// otherwise, we'll return what was stored in localStorage
//
// !!!NOTE - ajax sites rely on local storage but this causes bugs in static sites
// where a clean URL will cause the dropdown to not always match that of the actual
// content sort order (e.g. naviate to a different collection after sorting a collection)
function getSortMethod (useLocalStorage) {
  const ajaxed = (window.query && window.query.sort_by) ? window.query.sort_by : undefined;
  const local = window.localStorage.getItem(storageKey);
  const queryParams = _(window.location.search.substr(1).split('&')).compact();

  if (!_.isEmpty(ajaxed)) {
    return ajaxed;
  }

  if (!_.isEmpty(queryParams)) {
    const qsLookup = {};
    _(queryParams).map(function (param) {
      const keyValuePair = param.split('=');
      qsLookup[keyValuePair[0]] = keyValuePair[1];
    });

    const sortQueryParam = qsLookup['sort_by'];
    if (sortQueryParam) {
      return sortQueryParam;
    }
  }

  if (useLocalStorage && local) {
    return local;
  }

  return $sortVideos.val();
}

// just saves the sortMethod to localStorage
function saveSortMethod (sortMethod) {
  window.localStorage.setItem(storageKey, sortMethod);
  return sortMethod;
}

// preselects the current sort method in the select's dropdown menu
function preselectSortMethod (sortMethod) {
  // pre-selects the sorting based on the most recent choice
  if (sortMethod) {
    $sortVideos.find('option[value="' + sortMethod + '"]').attr('selected', 'selected');
    $sortVideos.trigger('chosen:updated');
  }
}

// ---------------------------------------- public
// sets up the change listener to handle both async and sync sites
const handleSorting = function () {
  $sortVideos = $('#sortVideos');
  $sortVideos.chosen({ width: 'auto', disable_search: true });

  const handleSortOrderChange = function (event) {
    const sortMethod = saveSortMethod(event.target.value);

    const options = {
      sort_by: sortMethod,
      category: window.category && !(window.query && window.query.q) ? window.category.slug : undefined,
      page: (window.query && window.query.page) ? window.query.page : undefined,
      q: (window.query && window.query.q) ? window.query.q : undefined,
    };

    // help AJAX templates by adding the sort method on window.query
    if (window.query) {
      window.query.sort_by = sortMethod;
    }

    let ajaxLoader = null;
    if (_.has(window, 'BCLSP1') && _.has(window.BCLSP1, 'loadVideos')) {
      ajaxLoader = window.BCLSP1;
    } else if (_.has(window, 'BCCNPY') && _.has(window.BCCNPY.Router, 'loadVideos')) {
      ajaxLoader = window.BCCNPY.Router;
    }

    if (ajaxLoader) { //ajax-y version
      ajaxLoader.loadVideos(options).then(function () {
        preselectSortMethod(sortMethod);
      });
    } else { //static page version
      sortPage(sortMethod);
    }
  };

  // fade in to prevent FOUC (flash of unstyled content)
  // only perform fade the first time to prevent multi-fade
  // for AJAX sites (e.g. Marquee) - other sites fade on
  // every page load
  if (!sortWrapperVisible) {
    sortWrapperVisible = true;
    $('.sortVideos-wrapper').fadeIn();
  } else {
    $('.sortVideos-wrapper').show();
  }

  $('input[name="sortVideos"]').change(function (event) {
    $('.bc-sort-videos-mobile').modal('hide');
    handleSortOrderChange(event);
  });
  //old event got blown away, but we need to do more this time anyway
  $sortVideos.on('change', handleSortOrderChange);

  preselectSortMethod(getSortMethod());
};

$(document).ready(function () {
  handleSorting();
});

module.exports = {
  getSortMethod,
  handleSorting,
};
