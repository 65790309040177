const utils = require('./utils');
const MicroEvent = require('microevent');

/**
 * Orchestrates logic for after video finishes playing.
 *
 * The goal is to keep players, leadwall, and autoplay next
 * features as decoupled as possible. So, we use a simple form
 * of dependency injection to pass other features into this
 * orchestrator, and we use the MicroEvents mixin library
 * to allow 0 knowledge message passing between different features
 * of the pubsite.
 *
 * You MUST create the videoFinisher with the `new` keyword for
 * MicroEvents to work.
 *
 * To kickoff the video finishing logic, trigger the video:finished
 * event:
 *
 *     videoFinisher.trigger('video:finished');
 */
class VideoFinisher {
  constructor (deps) {
    this.nextVideo = deps.nextVideo; // AKA: autoplayNext
    deps.MicroEvents.mixin(this);
    this.bind('video:finished', this.onVideoFinished);
  }

  onVideoFinished () {
    return this.nextVideo();
  }

  onNextVideo (callback) {
    if (global.site.isEdit || global.bcGallery.mute) {
      console.log('ignoring autoplayNext for WYSIWYG');
      this.nextVideo = function () { /* no-op */ };
      return;
    }
    console.log('changed autoplayNext impl');
    this.nextVideo = callback;
  }
}

// Dependency Injection
const dependencies = {
  nextVideo: utils.nextVideo,
  MicroEvents: MicroEvent,
};

const videoFinisher = new VideoFinisher(dependencies);
module.exports = {
  videoFinisher,
  _videoFinisher: videoFinisher, // For unit testing
};
