const { videoFinisher } = require('./videofinisher');
const $ = require('jquery');

const _vjs = {
  listeners: [],
  reset: function () {
    this.listeners = [];
  },
  emit: function (player) {
    for (let i = 0; i < this.listeners.length; ++i) {
      this.listeners[i](player);
    }
  },
};

function hideIMA3Iframe () {
  const ima3container = this.el().querySelector('.vjs-ima3-ad-container');
  if (ima3container) {
    ima3container.style.display = 'none';
  }
}

function disableIMA3 (player) {
  //Disables IMA3 for YouTube videos as it violates YouTube's T&C and it breaks playback on iOS
  //due to the fact that iOS doesn't honor pointer-events properly on iframes
  if (player.ima3 && player.ima3.settings) {
    player.ima3.settings.bcGalleryOldServerUrl = player.ima3.settings.serverUrl;
    player.ima3.settings.serverUrl = '#';
    //Try to hide the IMA3 iframe now and whenever the player is ready
    hideIMA3Iframe.call(player);
    player.on('ready', hideIMA3Iframe);
  }
}

function enableIMA3 (player) {
  player.off('ready', hideIMA3Iframe);
  if (player.ima3 && player.ima3.settings && player.ima3.settings.bcGalleryOldServerUrl) {
    player.ima3.settings.serverUrl = player.ima3.settings.bcGalleryOldServerUrl;
    const ima3container = player.el().querySelector('.vjs-ima3-ad-container');
    if (ima3container) {
      ima3container.style.display = null;
    }
  }
}

const updateAudioTrack = (player, languageCode) => {
  const audioTracks = player.audioTracks();
  for (let i = 0; i < audioTracks.length; i++) {
    if (audioTracks[i].language && languageCode.toLowerCase() === audioTracks[i].language.toLowerCase()) {
      audioTracks[i].enabled = true;
      break;
    }
  }
};

function passSAMLPropertyToPlayer (player) {
  const experienceData = global.bcGallery?.filteredAssemblerData;
  const samlAttributes = experienceData?.samlAttributes;
  const trackSAMLProperty = experienceData?.sso?.trackSAMLProperty;

  if (trackSAMLProperty && samlAttributes[trackSAMLProperty]) {
    player?.bcAnalytics?.client?.setUser(samlAttributes[trackSAMLProperty]);
  }
}

function initPlayer (video) {
  const videoId = video.getAttribute('data-video-id');
  const videoSource = video.getAttribute('data-video-source');
  video.setAttribute('data-account', global.bc_gallery.player.account);
  video.setAttribute('data-player', global.bc_gallery.player.id);
  video.setAttribute('data-embed', 'default');
  if (videoSource === 'youtube') {
    global.videojs.options.techOrder.unshift('youtube');
    video.setAttribute('data-video-id', '');
  }
  const id = video.id;
  let player;
  const ssoLoggedIn = global.bcGallery.filteredAssemblerData.ssoLoggedIn;
  const playbackAuthEnabled = global.bcGallery.filteredAssemblerData.account.playbackAuthEnabled;
  if (playbackAuthEnabled && ssoLoggedIn) {
    video.setAttribute('data-video-id', '');
    player = global.bc(video) || (id && global.videojs(id));

    $.ajax(`${window.baseUrl}/api/video/${videoId}/playbackauthtoken`, {
      dataType: 'json',
      success: (data) => {
        const authToken = data.result;
        player.catalog.get({
          type: 'video',
          id: videoId,
          bcovAuthToken: authToken,
        })
          .then(player.catalog.load)
          .catch(err => {
            global.videojs.log('Error fetching video: ', err);
          });
      },
      error: (xhr, options, err) => {
        global.videojs.log('Error fetching video: ', err);
      },
    });
  }
  else {
    //Old versions of global.bc (version 1.XX) don't return the player, but we can get it with global.videojs(id)
    player = global.bc(video) || (id && global.videojs(id));
  }

  if (videoSource === 'youtube') {
    disableIMA3(player);
    player.mediainfo = {
      id: videoId,
      name: video.getAttribute('data-video-name'),
      description: video.getAttribute('data-video-description'),
    };
    player.src({
      type: 'video/youtube',
      src: 'https://www.youtube.com/watch?v=' + videoId,
    });

    //Disable Youtube autoplay videos for IOS and Android devices
    if (global.videojs.browser.IS_IOS || global.videojs.browser.IS_ANDROID) {
      player.autoplay(false);
    }
  }
  return player;
}

//Add a listener function for when a player is setup (also immediately runs the callback with all existing players)
function onPlayerSetup (callback) {
  if (!global.bc_gallery.player || !global.bc_gallery.player.id) {
    return;
  }
  _vjs.listeners.push(callback);
  const players = global.videojs.getPlayers ? global.videojs.getPlayers() : global.videojs.players;
  for (const i in players) {
    if (players[i]) {
      callback(players[i]);
    }
  }
}

//Tear down all players under the root element (or all players in the document if root isn't specified)
function teardownPlayers (root) {
  if (!global.bc_gallery.player || !global.bc_gallery.player.id) {
    return;
  }
  const players = global.videojs.getPlayers ? global.videojs.getPlayers() : global.videojs.players;
  for (const i in players) {
    if (players[i] && (!root || root.contains(players[i].el()))) {
      // For VE template to display background video, it cannot be disposed
      if (players[i].el() && players[i].el().id !== 'backgroundPlayer') {
        players[i].dispose();
      }
    }
  }
}

//Set up all players under the root element (or all players in the document if root isn't specified)
function setupPlayers (root) {
  if (!global.bc_gallery.player || !global.bc_gallery.player.id) {
    return;
  }
  const videos = (root || document).querySelectorAll('video');
  for (let i = 0; i < videos.length; ++i) {
    const video = videos[i];
    const players = global.videojs.getPlayers ? global.videojs.getPlayers() : global.videojs.players;
    if (video.player || players[video.playerId]) {
      global.videojs.log('Ignoring already initialized player');
      continue;
    }

    const player = initPlayer(video);

    passSAMLPropertyToPlayer(player);
    _vjs.emit(player);

    if (global.bcGallery.filteredAssemblerData.site.useMultiLingualAudio) {
      player.on('loadedmetadata', () => {
        updateAudioTrack(player, global.bcGallery.filteredAssemblerData.locale.language);
      });
    }

    if (!i) {
      //Only set the ended and lead-wall events on the first player for now
      //TODO: if we start allowing multiple players on a single page, figure out how to do this for all players

      player.on('ended', function () {
        const milliseconds = global.currentVideo ? global.currentVideo.duration : 0;
        const seconds = Math.round(milliseconds / 1000);
        videoFinisher.trigger('video:finished', seconds);
      });
    }
  }
}

function performPlayerPlay (player, video, callback) {
  if (!callback) {
    callback = function () {};
  }
  if (video.source === 'youtube') {
    disableIMA3(player);
    player.poster('');
    player.mediainfo = {
      id: video.id,
      name: video.name,
      description: video.shortDescription,
    };
    player.src({
      type: 'video/youtube',
      src: 'https://www.youtube.com/watch?v=' + video.id,
    });
    callback();
  } else {
    enableIMA3(player);
    player.catalog.getVideo(video.id, function (error, video) {
      try {
        player.catalog.load(video);
      } catch (e) {
        console.warn('error loading video');
        console.warn(e);
        return callback(e);
      }
      callback();
    });
  }
}

module.exports = {
  onPlayerSetup,
  teardownPlayers,
  setupPlayers,
  performPlayerPlay,
};
