module.exports = {
  sites: {
    port: 80,
    hostname: 'stage5.gallerysites.net',
  },
  liveStatus: {
    endpointBaseUrl: 'https://s3.amazonaws.com/bc-gallery-qa-assets/live-status-stage5',
  },
  edgeApi: {
    endpointBaseUrl: 'https://edge.api.qa.brightcove.com/playback/v1/accounts/',
  },
};
